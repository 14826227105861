<template>
  <section>
    <div class="content-header">
      <h2>Identifying transferable skills</h2>
    </div>
    <div class="content-wrapper">
      <p>Students might not know that they have or are developing transferable skills—skills that can be used in different settings and that aren’t exclusive to any one field or occupation.</p>
      <p>Transferable skills include:</p>
      <ul>
        <li>Fundamental skills like communicating, managing information and problem solving</li>
        <li>Teamwork skills like collaborating on projects</li>
        <li>Personal management skills like demonstrating a positive attitude and working safely</li>
      </ul>
      <p>Centennial College’s helpful tip sheet, <a :href="`${publicPath}docs/career/TransfSkills.doc`" download>Identifying Your Transferrable Skills</a> can help your students identify and promote their own transferable skills to bolster their job search. Used with permission from Tracey Lloyd.</p>
      <p class="pt-3 lm">Learn more</p>
      <p>How can your work in the classroom contribute to building transferable skills and career competencies? In <a href="https://careerwise.ceric.ca/2019/03/04/lessons-from-wilfrid-laurier-universitys-career-integrated-learning-program/#.YYV7GtbMJuK" target="_blank">this article</a>, Jan Basso, a recognized leader in the field of post-secondary career development, describes how to do just that, with a career-integrated learning program at Wilfrid Laurier University.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '04',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
